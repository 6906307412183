// import { i18n } from '@/composables/useLocale';
// import d from '@/utils/d';

export default function (str: string) {
    return str;
    // if (i18n.te(str, i18n.locale)) {
    //     return i18n.t(str);
    // } else {
    //     d("Нету перевода - " + str);
    //     return str;
    // }
}
