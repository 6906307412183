export const SUPPORT_LOCALES = [
    {
        code: 'ru',
        name: 'Русский',
    },
    {
        code: 'en',
        name: 'English',
    },
    {
        code: 'kz',
        name: 'Қазақша',
    },
];
export const DEFAULT_LOCALE = 'ru';