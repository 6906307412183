import { DEBUG } from "@/configs/app";
import { useCookies } from "vue3-cookies";
import moment from "moment";

interface IType {
  styles: string,
  aliases: string[]
}

const TYPES: IType[] = [
    {
        styles: 'background: rgba(59, 125, 221, 0.15); color: #3b7ddd; padding: 2px 7px; font-weight: 900; border-radius: 2px; margin: 2px 4px 2px 10px;',
        aliases: ['info', 'primary', 'NCALayer', 'socket'],
    },
    {
        styles: 'background: rgba(28, 187, 140, 0.15); color: #1cbb8c; padding: 2px 7px; font-weight: 900; border-radius: 2px; margin: 2px 4px 2px 10px;',
        aliases: ['success', 'router'],
    },
    {
        styles: 'background: rgba(220, 53, 69, 0.15); color: #dc3545; padding: 2px 7px; font-weight: 900; border-radius: 2px; margin: 2px 4px 2px 10px;',
        aliases: ['danger', 'api'],
    },
    {
        styles: 'background: rgba(252,185,44, 0.15); color: #c18302; padding: 2px 7px; font-weight: 900; border-radius: 2px; margin: 2px 4px 2px 10px;',
        aliases: ['warning', 'userStore'],
    },
];

const { cookies } = useCookies();
const debugCookie = cookies.get('debug');

function logInfo(...args: any[]) {
    let time = moment(new Date).format('HH:mm:ss');
    console.info('%c' + time + '%cinfo', 'color: #333;', 'background: rgba(59, 125, 221, 0.15); color: #3b7ddd; padding: 2px 7px; font-weight: 900; border-radius: 2px; margin: 2px 4px 2px 10px;', ...args);
}

export function log(...args: any[]): void {
    if (DEBUG || debugCookie) {
        let time = moment(new Date).format('HH:mm:ss');
        if (typeof args[0] === 'string') {
            let [ type, name, subName, subSubName ] = args[0].split(':');
            let findAlias: IType | undefined = TYPES.find((t: IType) => t.aliases.includes(type));
            if (findAlias) {
                args.shift();
                console.info('%c' + time + '%c' + ([ type, name, subName, subSubName ].filter(i => i).join(':') || type), 'color: #333;', findAlias.styles, ...args);
            } else {
                logInfo(...args);
            }
        } else {
            logInfo(...args);
        }
    }
}
